import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Container from "common/src/components/UI/Container";
import Heading from "reusecore/src/elements/Heading";
import { ContentDiv } from './content.style';

const BannerSection = ({
  row,
  title,
  headerStyle,
  authorStyle,
  content,
  author
}) => {
  return (
    <React.Fragment>
      <div>
        <Container noGutter width="880px">
          <Box {...row}>
            <Heading content={title} {...headerStyle} />
            <Heading content={<span>written by <strong>{author}</strong></span>} {...authorStyle} />
            <ContentDiv dangerouslySetInnerHTML={{ __html: content }} />
          </Box>
        </Container>
      </div>
    </React.Fragment>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  headerStyle: PropTypes.object,
  authorStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'stretch',
  },
  headerStyle: {
    as: 'h1',
    color: '#030b16',
    fontSize: ['30px', '30px', '35px', '50px', '50px'],
    fontWeight: '100',
    mt: '1em',
    lineHeight: '1.2em',
    letterSpacing: '-0.011em',
  },
  authorStyle: {
    as: 'p',
    mb: '1em',
    fontWeight: '400',
  }
};

export default BannerSection;
