import styled from 'styled-components';

export const HeaderSpacer = styled.section`
  background-color: #030b16;
  padding-bottom: 87px;
  
  .portfolio_navbar {
    padding: 20px 0 20px 0!important;
  }
`;

export const BottomWrapper = styled.section`
  border-top: 1px solid #ccc;
  margin-top: 4em;
`;

export const ContentDiv = styled.div`
  line-height: 32px;
  font-size: 21px;

  blockquote {
    box-shadow: inset 3px 0 0 0 rgba(41, 41, 41, 1);
    margin-left: -20px;
    padding-left: 23px;
  }
  
  h2 {
    padding-top: 1em;
  }
  
  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    padding: 0.5em 0;
  }
  
  figcaption {
    font-size: 0.7em;
    text-align: center;
  }
  
  ul {
    padding: 1em 2em 0 2em;
  }
  
  li {
    padding-bottom: 1em;
    display: list-item;
    text-align: -webkit-match-parent;
    list-style-type: disc;
  }
`;

export default HeaderSpacer;
