import React, { Fragment } from 'react';
import { graphql } from "gatsby"
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { portfolioTheme } from 'common/src/theme/portfolio';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'common/src/containers/Portfolio/portfolio.style';

import Navbar from 'common/src/containers/Portfolio/Navbar';
import Footer from 'common/src/containers/Portfolio/Footer';
import SEO from '../../components/seo';
import Content from './content';
import { HeaderSpacer, BottomWrapper } from "./content/content.style";
import ComponentRenderer from "../../components/component-renderer";

export default ({ data }) => {
  const { title, content, author, components } = data.datoCmsArticle;

  return (
    <ThemeProvider theme={portfolioTheme}>
      <Fragment>
        <SEO title="We Are Serverless"/>
        <ResetCSS/>
        <GlobalStyle/>
        <ContentWrapper>
          <HeaderSpacer>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar hideMenu={true} />
              </DrawerProvider>
            </Sticky>
          </HeaderSpacer>
          <Content title={title} author={author} content={content}/>
        </ContentWrapper>
        <BottomWrapper>
          <ComponentRenderer components={components}/>
          <Footer/>
        </BottomWrapper>
      </Fragment>
    </ThemeProvider>
  )
};

export const query = graphql`
  query ArticleQuery($slug: String!) {
    datoCmsArticle(slug: { eq: $slug }) { 
      title
      slug
      content
      author
      components {
        ... on DatoCmsComponentCtaBlock {
          internal {
            type
          }
          title
          description
          link        
        }
      }
    }
  }
`;
